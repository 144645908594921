import React, { useState, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import './SignUp.css';

const SignUp = ({ switchView }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signUpError, setSignUpError] = useState('');
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const { signUp } = useContext(AuthContext);



  const handleSignUp = async (e) => {
    e.preventDefault();
    setSignUpError(''); // Reset error state on new submission
    try {
      await signUp({ name, email, password });
      // Handle the new user (e.g., show a confirmation message)
      setSignUpSuccess(true); // Set the success state
      // Optionally, switch to confirm signup view
      switchView('confirmSignUp', email);
    } catch (error) {
      // Handle errors (e.g., username already taken)
      setSignUpError(error.message || 'An unexpected error occurred.');
    }
  };
  

  return (
    <div className="sign-up-container">
    <h2 className="sign-up-title">Sign Up</h2>
    {signUpSuccess ? (
      <div className="sign-up-success-message">
        <p>Signup successful! Please check your email for the verification code.</p>
        <button onClick={() => switchView('confirmSignUp')}>Confirm Email</button>
      </div>
    ) : (
      <form className="sign-up-form" onSubmit={handleSignUp}>
        {/* Form fields go here */}
        <input type="text" placeholder="Name" required value={name} onChange={(e) => setName(e.target.value)}/>
        <input type="email" placeholder="Email" required value={email} onChange={(e) => setEmail(e.target.value)}/>
        <input type="password" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)}/>
        {/* ...other input fields */}
        <button type="submit">Sign Up</button>
      </form>
    )}
    {signUpError && <div className="sign-up-error">{signUpError}</div>}
    <button className="sign-in-link" onClick={() => switchView('signIn')}>
      Already have an account? Sign In
    </button>
    </div>
  );
};

export default SignUp;
