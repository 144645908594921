import React, { useContext, useState } from 'react';
import './HeaderProfileMenu.css';
import { ReactComponent as UserSolid } from '../../assets/icons/user-solid.svg';
import { AuthContext } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';


function ProfileMenu() {
    const { currentUser } = useContext(AuthContext);
    const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown visibility
    const navigate = useNavigate();

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen); // Function to toggle dropdown

    const navigateToAuth = () => {
        navigate('/auth'); // Navigate to auth screen
    };

    const navigateToProfile = () => {
        navigate('/profile'); // Navigate to profile screen
    }

    const navigateToLogout = () => {
        navigate('/logout'); // Navigate to logout screen
    }

    if (!currentUser) {
        // Render sign-in option if currentUser is null
        return (
            <div className="profile-menu">
                <button className="signin-btn" onClick={navigateToAuth}>Sign In / Sign Up</button>
            </div>
        );
    }

    // User is logged in, proceed with normal rendering
    const name = currentUser.attributes.name;

    return (
        <div className="profile-menu">
        <button className="profile-button" onClick={toggleDropdown}>
            <UserSolid className="profile-image" />
            <span>{name}</span>
        </button>
        {dropdownOpen && (
            <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
                <button onClick={navigateToProfile} className="dropdown-item">Profile</button>
                <button onClick={navigateToLogout} className="dropdown-item">Logout</button>
            </div>
        )}
    </div>
    );
}

export default ProfileMenu;