import React, { useState, useEffect } from 'react';
import { Oval } from "react-loader-spinner";
import './VisitSummary.css'



const VisitSummary = ( props ) => {

    console.log("visit summary rendered");
    console.log("visit summary visitId", props.visitId);

    const [visitData, setVisitData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [scenarioRestarted, setScenarioRestarted] = useState(false);


    const getVisitData = (visitId) => {
        fetch('https://ct2k68afha.execute-api.us-east-1.amazonaws.com/Prod/visit/analyze/' + visitId, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(response => response.json())
            .then(data => {
                console.log("visit summary data", data);
                setVisitData(data);
                setLoading(false);
            })
            .catch(error => console.error('Error:', error));
    };

    const LoadingSpinner = () => (
        <div className="loading-spinner">
            <h2>Assessing your virtual visit...</h2>
                <Oval
                    color="#007bff"
                    secondaryColor="#80BDFF80"
                    size={100}
                    loading={loading}
                />

        </div>
      );

    const handleRestart = () => {
        setVisitData(null);
        setScenarioRestarted(true);
        console.log("scenarioRestarted: ", scenarioRestarted)
        props.onScenarioRestart();
        };

    useEffect(() => {
        console.log("visitId: ", props.visitId)
        getVisitData(props.visitId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (!visitData) return (
        <LoadingSpinner className='loading-spinner'/>
    );

    return (
        <div className="visit-summary">
            <h2>Visit Summary</h2>
            <p>Patient Name: {visitData.visit.patient.first_name + " " + visitData.visit.patient.last_name}</p>
            <p>Patient Age: {visitData.visit.patient.age}</p>
            <p>Diagnosis: {visitData.visit.diagnosis.replace(/_/g, " ").toLowerCase().split(" ")}</p>
            <p>Feedback:</p>
            <p>Overall Feedback:</p>
            <p>{visitData.analysis.overallFeedback}{visitData.analysis.overall_feedback}</p>
            <p>Strengths:</p>
            <ul>
                {visitData.analysis.strengths.map((str, index) => (
                    <li key={index}>{str}</li>
                ))}
            </ul>
            <p>Areas for Improvement:</p>
            <ul>
                {visitData.analysis.weaknesses.map((str, index) => (
                    <li key={index}>{str}</li>
                ))}
            </ul>
            <p>Overall Score: {visitData.analysis.score}</p>
            <button onClick={handleRestart}>New Scenario</button>
        </div>
    );

};

export default VisitSummary;