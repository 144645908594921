import React from 'react';
import './Header.css';
import ProfileMenu from '../HeaderProfileMenu';

function Header() {
    return (
        <header className="header">
            <ProfileMenu />
        </header>
    );
}

export default Header;
