import React, { createContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    checkUser();
  }, []);

  const signIn = async (username, password) => {
    try {
      const user = await Auth.signIn(username, password);
      setCurrentUser(user);
    } catch (error) {
      console.log('error signing in', error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setCurrentUser(null);
    } catch (error) {
      console.error('Error signing out user ', error);
    }
  };

  const signUp = async (userInfo) => {
    try {
      const { name, email, password } = userInfo;
      const user = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          name,
        },
      });
      setCurrentUser(user);
    } catch (error) {
      console.log('error signing up:', error);
      throw error;
    }
  };

  const confirmSignUp = async (username, code) => {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      console.log('error confirming sign up', error);
      throw error;
    }
  };

  const resendSignUp = async (username) => {
    try {
      await Auth.resendSignUp(username);
    } catch (error) {
      console.log('error resending the sign up code', error);
      throw error;
    }
  };

  const forgotPassword = async (username) => {
    try {
      await Auth.forgotPassword(username);
    } catch (error) {
      console.log('error forgot password', error);
      throw error;
    }
  };

  const resetPassword = async (username, code, newPassword) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (error) {
      console.log('error resetting password', error);
      throw error;
    }
  };

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setCurrentUser(user);
    } catch (error) {
      console.error('Not signed in', error);
      setCurrentUser(null);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, signIn, signOut, signUp, confirmSignUp, resendSignUp, forgotPassword, resetPassword, checkUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;