// awsConfig.js
const awsConfig = {
    Auth: {
      region: 'us-east-1', // e.g. 'us-west-2'
      userPoolId: 'us-east-1_yLrcuLRpF', // e.g. 'us-west-2_1a2b3c4d5'
      userPoolWebClientId: '5jnca4giue8ti794h15nfh612d', // e.g. '1h2j3k4l5mnopq6rstuv'
      // identityPoolId: 'YOUR_IDENTITY_POOL_ID', // e.g. 'us-west-2:12345678-9abc-def0-1234-56789abcdef0'
      // ... any other Auth configuration
    },
    // ... other AWS configurations like API Gateway, S3, etc.
  };
  
  export default awsConfig;
  