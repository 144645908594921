import React, { useState, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import './ConfirmSignUp.css';

const ConfirmSignUp = ({ switchView }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [email, setEmail] = useState(''); // If needed
  const { confirmSignUp, resendSignUp } = useContext(AuthContext);

  const handleConfirmSignUp = async (e) => {
    e.preventDefault();
    try {
      await confirmSignUp(email, verificationCode);
      // Handle the success scenario - maybe auto-login or redirect to sign-in
      switchView('signIn'); // Assuming you have a switchView method to change the displayed component
    } catch (error) {
      // Handle errors (e.g., wrong verification code)
      // alert(error.message); // Or display the error in the UI
    }
  };

  // Optional: Implement a method to resend the code
  const resendCode = async () => {
    try {
      // Assuming the AuthService has a resendCode method
      await resendSignUp(email);
      // alert('Verification code resent. Please check your email or SMS.');
    } catch (error) {
      alert(error.message); // Or display the error in the UI
    }
  };

  return (
    <div className="confirm-signup-container">
      <h2>Confirm Signup</h2>
      <form onSubmit={handleConfirmSignUp}>
        <input
          type="text"
          placeholder="Username"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Verification Code"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          required
        />
        <button type="submit">Confirm</button>
        <button type="button" onClick={resendCode}>
          Resend Code
        </button>
      </form>
    </div>
  );
};

export default ConfirmSignUp;
