import React, { useState, useEffect} from 'react';
import VisitFilters from '../VisitFilters';
import VisitChatApp from '../VisitChatApp';
import VisitSummary from '../VisitSummary';
import { Oval } from "react-loader-spinner";


import './Visit.css';

const Visit = () => {
  const [visitData, setVisitData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [visitConcluded, setVisitConcluded] = useState(false);

  console.log("patient page rendered");
  console.log("patient page visitData", visitData);

  const handleVisitStart = (data) => {
    setLoading(true); 
    setHasStarted(true);
    setVisitData(data); 
  };

  const handleScenarioRestart = () => {
    setVisitConcluded(false);
    setVisitData(null);
    setLoading(false);
    setHasStarted(false);
  };

  const handleVisitEnd = () => {
    setVisitConcluded(true);
  };


  useEffect(() => {
    if (hasStarted && visitData) {
      setLoading(false); 
      setHasStarted(false); 
    }
  }, [visitData, hasStarted]);

  /*
  const Placeholder = () => (
    <div className="placeholder">
      <h2>Welcome to the Patient Page</h2>
      <p>Please select filters and start the visit to see the chat app.</p>
    </div>
  );
  */
  
  const LoadingSpinner = () => (
    <div className="loading-spinner">
        <h2>Creating your virtual patient visit...</h2>
      <Oval
            color="#007bff"
            secondaryColor="#3cb7ff"
            size={100}
            loading={loading} 
        />
    </div>
  );


  return (
    <div className="container">
        {visitConcluded ? (
            <VisitSummary visitId={visitData.visit_id} onScenarioRestart={handleScenarioRestart}/>
        ) : (
            <div className="chat-app">
                {loading ? (
                    <LoadingSpinner />
                ) : visitData ? (
                    <VisitChatApp visitData={visitData} onVisitEnd={handleVisitEnd}/>
                ) : (
                    <VisitFilters onVisitStart={handleVisitStart} />
                )}
            </div>
        )}
    </div>
);


};
export default Visit;
