import React from 'react';
import { Link } from 'react-router-dom';
import './SideNavButton.css';
import { ReactComponent as HouseMedicalSolid } from '../../assets/icons/house-medical-solid.svg';
import { ReactComponent as UserSolid } from '../../assets/icons/user-solid.svg';
import { ReactComponent as Logo } from '../../assets/icons/virtuclinic-logo.svg'; 

function SideNavButton({ iconName, text, route, isCollapsed, isLogo }) {

    let SvgIcon;

    switch(iconName) {
        case 'HouseMedicalSolid':
            SvgIcon = HouseMedicalSolid;
            break;
        case 'UserSolid':
            SvgIcon = UserSolid;
            break;
        case 'Logo':
            SvgIcon = Logo;
            break;
        default:
            SvgIcon = null;
    }

    return (
        <Link to={route} className={`nav-item`}>
            {SvgIcon && <SvgIcon className='nav-item-icon'/>}
            {!isCollapsed && <span>{text}</span>}
        </Link>
    );
}

export default SideNavButton;
