import React, { useState, useEffect } from 'react';
import Select from 'react-select';

// Styles for the dropdown
const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? 'lightgreen'
          : isFocused
          ? 'lightgray'
          : null,
        color: 'black',
      };
    },
  };
  

const MultiSelectFilter = ({ name, onFilterChange, options }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (options) => {
    setSelectedOptions(options);
  };

  useEffect(() => {
    onFilterChange(name, selectedOptions);
  }, [name, onFilterChange, selectedOptions]);

  return (
    <div>
      <Select
        options={options}
        isMulti
        onChange={handleChange}
        styles={customStyles}
      />
    </div>
  );
};

export default MultiSelectFilter;
