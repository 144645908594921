import React, { useState, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import './ResetPassword.css';

const ResetPassword = ({ switchView }) => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { resetPassword } = useContext(AuthContext);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(email, code, newPassword);
      setSuccessMessage('Your password has been reset successfully. You can now sign in with your new password.');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.message);
      setSuccessMessage('');
    }
  };

  return (
    <div className="reset-password-container">
      <h2 className="reset-password-title">Reset Password</h2>
      <form className="reset-password-form" onSubmit={handleResetPassword}>
        <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input type="text" placeholder="Enter the code" value={code} onChange={(e) => setCode(e.target.value)} required />
        <input type="password" placeholder="Enter your new password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
        <button type="submit">Reset Password</button>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}
        <button className="sign-in-link" onClick={() => switchView('signIn')}>
          Back to Sign In
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;