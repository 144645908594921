import React from 'react';
import { Link } from 'react-router-dom';
import landingPageImage from '../../assets/landing-page-1.svg'; // Add import for the SVG image
import './Home.css'


function Home() {

    return (
        <div className="home">
            <div className="text-section">
                <h1>Master Your Clinical Skills With Advanced AI-Powered Simulations</h1>
                <p>Welcome to VirtuClinic – the cutting-edge app that transforms the way nursing and medical students practice and perfect their clinical skills. Our advanced generative AI technology offers an immersive learning experience, simulating a wide range of patient interactions and clinical scenarios. Get ready to step into the future of medical education!</p>
                <button className="button"> 
                    <Link to="/visit">Start a Visit</Link>
                </button>
            </div>
            <div className="image-section">
                <img src={landingPageImage} alt='Clinical Training' />
            </div>
        </div>
    );
}

export default Home;
