import React, { useState } from 'react';
import './VisitMessageInput.css';

function VisitMessageInput({ onSendMessage }) {  // Accept a callback as a prop
    const [message, setMessage] = useState('');

    const handleSubmit = () => {
        if (message.trim() !== '') {
            onSendMessage(message);  // Call the callback with the message
            setMessage('');  // Clear the input after submission
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className="message-input">
            <input 
                type="text" 
                placeholder="Type your message here..." 
                value={message}
                onChange={e => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <button onClick={handleSubmit}>Send</button>
        </div>
    );
}

export default VisitMessageInput;
