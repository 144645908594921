import React, { useState, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import './ForgotPassword.css';

const ForgotPassword = ({ switchView }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { forgotPassword } = useContext(AuthContext);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword(email);
      setSuccessMessage('A password reset code has been sent to your email address. Please enter the code in the next step.');
      setErrorMessage('');
      setTimeout(() => {
        switchView('resetPassword');
      }, 3000); // 3 seconds delay
    } catch (error) {
      setErrorMessage(error.message);
      setSuccessMessage('');
    }
  };

  return (
    <div className="forgot-password-container">
      <h2 className="forgot-password-title">Forgot Password</h2>
      <form className="forgot-password-form" onSubmit={handleForgotPassword}>
        <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <button type="submit">Send Code</button>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}
        <button className="sign-in-link" onClick={() => switchView('signIn')}>
          Back to Sign In
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;