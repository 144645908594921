import React from 'react';
import './VisitSideBar.css';



function VisitSideBar({ visitData, onVisitEnd }) {
    const handleConcludeVisit = () => {
        // Here, we're sending a POST request to `/api/visit/conclude`.
        fetch('https://ct2k68afha.execute-api.us-east-1.amazonaws.com/Prod/visit/end', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(visitData),
        })
        .then(response => {
          if (response.status === 200) {
            onVisitEnd();
          } else {
            console.error('Failed to conclude visit. Response: ', response.status);
          }
        })
        .catch(error => console.error('Error:', error));
      };

    return (
        <div className="sidebar">
            <div className="patient-information">Patient information</div>
                    <div className="info-item">
                        <label>Name:</label>
                        <span>{visitData.patient.first_name + " " + visitData.patient.last_name}</span>
                    </div>
                    <div className="info-item">
                        <label>Age:</label>
                        <span>{visitData.patient.age}</span>
                    </div>
                    <div className="info-item">
                        <label>Gender:</label>
                        <span>{visitData.patient.gender}</span>
                    </div>
                    <button className="conclude-visit-button" onClick={handleConcludeVisit}>Conclude Visit</button>
        </div>
    );
}

export default VisitSideBar;
