import React, { useState } from 'react';
import SignIn from '../../components/SignIn';
import SignUp from '../../components/SignUp';
import ForgotPassword from '../../components/ForgotPassword';
import './AuthScreen.css'; // Make sure your CSS reflects these changes
import ConfirmSignUp from '../../components/ConfirmSignUp';
import ResetPassword from '../../components/ResetPassword';


const AuthScreen = () => {
  const [view, setView] = useState('signIn'); // Possible values: 'signIn', 'signUp', 'forgotPassword'

  const getForm = () => {
    switch (view) {
      case 'signIn':
        return <SignIn switchView={setView} />;
      case 'signUp':
        return <SignUp switchView={setView} />;
      case 'forgotPassword':
        return <ForgotPassword switchView={setView} />;
      case 'confirmSignUp':
        return <ConfirmSignUp switchView={setView} />;
      case 'resetPassword':
        return <ResetPassword switchView={setView} />;
      default:
        return null;
    }
  };

  return (
    <div className="auth-container">
      <div className="welcome-message">
        <h1>Welcome to VirtuClinic</h1>
        <p>Please {view === 'signIn' ? 'sign in' : view === 'signUp' ? 'sign up' : 'reset your password'} to continue.</p>
      </div>
      <div className="auth-form-container">
        {getForm()}
      </div>
      <div className="switch-view">
        {view !== 'signIn' && <button onClick={() => setView('signIn')}>Sign In</button>}
        {view !== 'signUp' && <button onClick={() => setView('signUp')}>Sign Up</button>}
        {view !== 'forgotPassword' && <button onClick={() => setView('forgotPassword')}>Forgot Password?</button>}
      </div>
    </div>
  );
};

export default AuthScreen;
