import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './layouts/MainLayout';
import Home from './screens/Home';
import Visit from './screens/Visit';
import { Amplify } from 'aws-amplify';
import awsConfig from './awsConfig';
import AuthScreen from './screens/AuthScreen';
import { AuthProvider } from './AuthContext';
import Logout from './components/Logout';

Amplify.configure(awsConfig);

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<AuthScreen />} />
            <Route path="/visit" element={<Visit />} />
            <Route path="/logout" element={<Logout />} />
            {/* Add other routes as needed */}
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;

