import React, { useState, useEffect, useRef } from 'react';
import './VisitChat.css';

import VisitMessageInput from '../VisitMessageInput';

function VisitChat({ visitData }) {
    const [messages, setMessages] = useState([]); // To store the chat messages
    const handleMessageRef = useRef();
    const messagesEndRef = useRef(null);
    const [messageCount, setMessageCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [socket, setSocket] = useState(null); 

    // Function to scroll to the bottom of chat
    /*
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    */

    useEffect(() => {
        const chatContainer = document.getElementById('chat-messages'); // replace with your chat container id
        if (!chatContainer) return;
    
        const observer = new MutationObserver(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        });
    
        observer.observe(chatContainer, { childList: true });
    
        return () => observer.disconnect();
    }, []);

    // On component mount and when messages change
    useEffect(() => {
        // Set up WebSocket connection
        const socket = new WebSocket('wss://2pmi5x3xd7.execute-api.us-east-1.amazonaws.com/Prod');

        socket.onopen = () => {
            console.log('Connected to the WebSocket');
            setSocket(socket);
        };

        // Clean up the WebSocket connection when the component is unmounted
        return () => {
            socket.close();
        };
    }, []);

    // Function to handle scroll down to bottom of chat
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, [messageCount]);

    useEffect(() => {
        const chatContainer = document.getElementById('chat-container');
        if (!chatContainer) return;
    
        const observer = new MutationObserver(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        });
    
        observer.observe(chatContainer, { childList: true });
    
        return () => observer.disconnect();
    }, []);


    // Function to handle messages
    useEffect(() => {
        console.log("useEffect called")
        const handleMessage = (event) => {
            const message = JSON.parse(event.data);
            console.log(message);
        
            setMessages((prevMessages) => {
            // check if this message is part of an existing message
            const existingMessageIndex = prevMessages.findIndex(
                (m) => m.id === message.id
            );
        
            if (existingMessageIndex !== -1) {
                // if it is, combine this message with the existing one
                // create a new copy of the existing message
                const existingMessage = { ...prevMessages[existingMessageIndex] };
                // update the content of the existing message
                existingMessage.content += message.content;
                // create a new array with the updated message
                const newMessages = prevMessages.map((m, i) => 
                i === existingMessageIndex ? existingMessage : m
                );
                return newMessages;
            } else {
                // if not, create a new message
                return [
                ...prevMessages,
                { role: 'assistant', content: message.content, id: message.id },
                ];
            }
            });
            setMessageCount(prevCount => prevCount + 1);
            setLoading(false);
        };
        handleMessageRef.current = handleMessage;
    }, []);  
        
    // Function to send messages
    const sendMessage = (input) => {
        console.log('Sending message...')
      
        if (!input.trim() || !socket) return;
      
        const updatedMessages = [
          ...messages,
          { role: 'user', content: input },
        ];
      
        setMessages(updatedMessages);
        setLoading(true);
      
        if (!visitData) {
          console.error('Visit data is not available');
          return;
        }
      
        const newMessage = {
          messages: updatedMessages,
          visit_id: visitData.visit_id,
        };
      
      
        socket.send(JSON.stringify(newMessage));
        console.log('Message sent to the backend:', newMessage);
      };

    useEffect(() => {
        if (!handleMessageRef.current || !socket) return;
        
        socket.addEventListener('message', handleMessageRef.current);
        
        return () => {
            socket && socket.removeEventListener('message', handleMessageRef.current);
        };
    }, [socket]);

    return (
        <div className="visit-chat">
            <div className="chat-header">
                <div className="chat-name">{visitData.patient.first_name}</div>
            </div>
    
            <div className="chat-messages" id="chat-messages">
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`chat-message ${message.role === 'user' ? 'user' : 'assistant'}`}
                    >
                        <div className="message-content">{message.content}</div>
                    </div>
                ))}
                {loading && (
                    <div className="chat-message assistant loading">
                        <div className="loading-dots">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                )}
                <div ref={messagesEndRef} />
            </div>
            <VisitMessageInput onSendMessage={sendMessage} />
        </div>
    );
}

export default VisitChat;
