import React, { useState, useContext} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import './SignIn.css'; 

const SignIn = ({ switchView }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { checkUser, signIn } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState('');

  // location.state.from will have the path they were redirected from if it was set
  const { from } = location.state || { from: { pathname: "/" } }; // default to the home page if no redirect path was set

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signIn(email, password);
      await checkUser();
      // Handle signed-in user
      console.log('from', from);
      navigate(from.pathname, { replace: true });
    } catch (error) {
      // Handle errors (e.g., user not found, incorrect password)
      if (error.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        switchView('confirmSignUp'); // Assuming you have a switchView method to change the displayed component
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <div className="sign-in-container">
      <h2 className="sign-in-title">Sign In</h2>
      <form className="sign-in-form" onSubmit={handleSignIn}>
        {/* Your input fields and sign-in button here */}
        <input type="email" placeholder="Email" required value={email} onChange={(e) => setEmail(e.target.value)}/>
        <input type="password" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)}/>
        <button type="submit">Sign In</button>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button className="forgot-password-link" onClick={() => switchView('forgotPassword')}>
          Forgot password?
        </button>
      </form>
    </div>
  );
};

export default SignIn;
