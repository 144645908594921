import React from 'react';
import Header from '../../components/Header';
import SideNav from '../../components/SideNav';
import './MainLayout.css';

function Layout(props) {
    return (
      <div className="layout-container">
        <SideNav />
        <div className="right-content">
            <Header />
            <main className="main-content">
                {props.children}
            </main>
        </div>
      </div>
    );
}
export default Layout;
