import React, { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import './Logout.css';

const Logout = () => {
    const { signOut } = useContext(AuthContext);

    const handleSignOut = async () => {
        try {
            await signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    handleSignOut();

  return (
    <div className="logout-container">
      <p>You have been logged out.</p>
      <p> Thank you for using VirtuClinic!</p>
    </div>
  );
};

export default Logout;
