import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SideNav.css';
import SideNavButton from '../SideNavButton';
import { ReactComponent as BarsSolid } from '../../assets/icons/bars-solid.svg';
import { ReactComponent as ChevronLeftSolid } from '../../assets/icons/chevron-left-solid.svg';
import { ReactComponent as Logo } from '../../assets/icons/virtuclinic-logo.svg';

function SideNav() {
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <aside className={`side-nav ${isCollapsed ? 'collapsed' : ''}`}>
            <Link to="/" className="logo-link">
                <div className="logo-container">
                    <Logo className="logo" />
                    <span className={`logo-text ${isCollapsed ? 'hidden' : ''}`}>VirtuClinic</span>
                </div>
            </Link>
            <div className="nav-item-divider"></div>
            <SideNavButton iconName='HouseMedicalSolid' text="Start Visit" route="/visit" isCollapsed={isCollapsed} isLogo={false}/>
            <SideNavButton iconName='UserSolid' text="Profile" route="/Profile" isCollapsed={isCollapsed} isLogo={false}/>
            <button className="toggle-btn" onClick={() => setIsCollapsed(!isCollapsed)}>
                {isCollapsed ? <BarsSolid /> : <ChevronLeftSolid />}
            </button>
        </aside>
    );
}

export default SideNav;
