import React from 'react';
import './VisitChatApp.css';

import VisitSidebar from '../../components/VisitSideBar';
import VisitChat from '../../components/VisitChat';



function Visit({ visitData, onVisitEnd }) {
    
    return (
        <div className="visit">
            <VisitSidebar visitData={visitData} onVisitEnd={onVisitEnd}/>
            <VisitChat visitData={visitData}/>
        </div>
    );
}

export default Visit;
