// VisitFilters.js
import React, { useState, useCallback } from 'react';
import MultiSelectFilter from '../../components/MultiSelectFilter';
import './VisitFilters.css';


const VisitFilters = ({ onVisitStart }) => {
  const [filters, setFilters] = useState({
    age_population: '',
    gender: '',
    diagnosis_type: ''
  });

  const handleFilterChange = useCallback((filterName, filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: filter.map(obj => obj.value)
    }));
  }, []);

  const selectRandomScenario = (filters) => {
    return fetch('https://ct2k68afha.execute-api.us-east-1.amazonaws.com/Prod/scenario/select_random', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"filters": filters})
    }).then(response => response.json());
  }
  
  const createVisit = (userId, scenarioId, filters) => {

    const payload = {
      "user_id": userId,
      "scenario_id": scenarioId,
      "filters": filters
    }

    return fetch('https://ct2k68afha.execute-api.us-east-1.amazonaws.com/Prod/visit/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)  
    }).then(response => response.json());
  }
  
  const startVisit = (event) => {
    event.preventDefault();
    onVisitStart(null);

    if (filters.diagnosis_type.includes("any")) {
      filters.diagnosis_type = [];
    }
    if (filters.age_population.includes("any")) {
      filters.age_population = [
        "pediatric",
        "adult",
        "geriatric"
      ];
    }

    console.log(filters)
  
    selectRandomScenario(filters)
      .then(scenario_data => {
        console.log(scenario_data);
        return createVisit("2d3638a1-bf7d-4d42-8680-1637daa4786b", scenario_data.scenario_id, filters);
      })
      .then(visit_data => {
        console.log(visit_data);
        onVisitStart(visit_data);
      })
      .catch(error => console.error(error));
  };
  

  return (
    <div className="visit-filters-container">
      <div className="filters-instructions-layout">
        <div className="filters-section">
          <form className="visit-filters-form">
            <label>
                  Age Population:
                  <MultiSelectFilter 
                  name="age_population"
                  onFilterChange={handleFilterChange}
                  options={[
                      {value: 'any', label: 'Any'},
                      {value: 'pediatric', label: 'Pediatric'},
                      {value: 'adult', label: 'Adult'},
                      {value: 'geriatric', label: 'Geriatric'}
                  ]} />
              </label>
              <label>
                Gender:
                <MultiSelectFilter 
                name="gender"
                onFilterChange={handleFilterChange}
                options={[
                      {value: 'any', label: 'Any'},
                      {value: 'male', label: 'Male'},
                      {value: 'female', label: 'Female'},
                      {value: 'other', label: 'Other'}
                  ]} />
              </label>
              <label>
                System:
                <MultiSelectFilter 
                name="diagnosis_type"
                onFilterChange={handleFilterChange}
                options={[
                      {value: 'any', label: 'Any'},
                      {value: 'cardiovascular', label: 'Cardiovascular'},
                      {value: 'respiratory', label: 'Respiratory'},
                      {value: 'gastrointestinal', label: 'Gastrointestinal'},
                      {value: 'neurological', label: 'Neurological'},
                      {value: 'musculoskeletal', label: 'Musculoskeletal'},
                      {value: 'endocrine', label: 'Endocrine'},
                      {value: 'genitourinary', label: 'Genitourinary'},
                      {value: 'hematological', label: 'Hematological'}
                  ]} />
              </label>
            </form>
          <div className="spacer" />
          <button className="start-visit-button" onClick={(event) => startVisit(event)}>
                Start Patient Visit
          </button>
        </div>
        <div className="instructions-section">
          <h2>How to Start a Simulation</h2>
          <p>Follow the steps below to customize and begin a new patient visit simulation.</p>
          <ol>
            <li>Select the age population for the simulation.</li>
            <li>Choose the gender you wish to simulate.</li>
            <li>Pick a diagnosis system to focus on.</li>
            <li>Click 'Start Patient Visit' to begin.</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default VisitFilters;
